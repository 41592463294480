export enum BoatTypeSectionType {
  Motor = 1,
  Sail,
  Catamaran
}

export class BoatTypeSectionDto {
  constructor(
    public readonly id: BoatTypeSectionType,
    public readonly name: string,
    public readonly types: number[],
  ) {
  }
}
