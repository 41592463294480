export enum JobStartDateType {
  Asap = 1,
  Week,
  Month,
  Date
}
export class JobStartDateDto {
  constructor(
    public readonly id: JobStartDateType,
    public readonly name: string,
  ) {
  }
}
