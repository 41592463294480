import {LabeledItem} from '../general';

const ALL_USER_ROLES: UserRoleType[] = [
  'role_boat_captain',
  'role_boat_manager',
  'role_boat_owner',
  'role_crew',
  'role_supplier',
];

export type UserRoleType = 'role_boat_captain' |
'role_boat_manager' |
'role_boat_owner' |
'role_crew' |
'role_supplier';

const LABELED_USER_ROLES: LabeledItem<UserRoleType>[] = [
  {
    id: 'role_boat_captain',
    name: 'roles.captain',
  },
  {
    id: 'role_crew',
    name: 'roles.crew',
  },
  {
    id: 'role_boat_owner',
    name: 'roles.owner',
  },
  {
    id: 'role_boat_manager',
    name: 'roles.manager',
  },
  {
    id: 'role_supplier',
    name: 'roles.supplier',
  },
];

export const isUserRole = (value: string | null): value is UserRoleType => ALL_USER_ROLES.includes(value as UserRoleType);

export const isCrewGroupRole = (value: UserRoleType): boolean => value === 'role_crew' || value === 'role_boat_captain';

export const isManagerGroupRole = (value: UserRoleType): boolean => value === 'role_boat_manager' || value === 'role_boat_owner';

export class RoleDto {
  constructor(
    public readonly name: UserRoleType,
    public readonly description: string,
  ) {
  }
}

export const getUserRoleName = (role: UserRoleType | null): string | null => {
  const userRole = LABELED_USER_ROLES.find(r => r.id === role);
  return userRole?.name || null;
};
