import {ApaTransactionFormType, ApaTransactionType} from '../charters';

export type PaymentMethodDepartment = 'all' | 'relate' | false;

export type PaymentMethodCreditCard = 'one' | 'all' | false;

export type PaymentMethodDto = Readonly<{
  id: number;
  name: string;
  category: boolean;
  subCategory: boolean;
  department: PaymentMethodDepartment;
  creditCard: PaymentMethodCreditCard;
  supplier: boolean;
  creditCardTopUp: boolean;
  outstandingPayment: boolean;
  bankAccount: boolean;
  bankAccountTopUp: boolean;
}>;

export enum ApaDepartmentStatus {
  Disabled,
  From,
  To,
  Standart
}

export type ApaPaymentMethodDto = Readonly<{
  id: number;
  name: string;
  apaType: ApaTransactionType[];
  sort: number;
  enableDepartment: ApaDepartmentStatus;
  creditCard: boolean;
  bankAccount: boolean;
  subCategory: boolean;
  vatIncluded: boolean | null;
  hiddenForAddForm?: boolean;
}>;

export type CardsPaymentMethodDto = Readonly<{
  id: number;
  name: string;
  ref: CardsRefDto;
  sort: number;
}>;

export type CardsRefDto = Readonly<{
  refId: number;
  refType: number;
}>;

export type ApaOperation = Readonly<{
  id: ApaTransactionType;
  name: string;
  formType: ApaTransactionFormType;
}>;

export type VatTypeItem = Readonly<{
  value: boolean | null;
  name: string;
}>;

export type PettyCashPaymentMethodDto = Readonly<{
  id: number;
  name: string;
  ref: CardsRefDto;
  sort: number;
}>;
