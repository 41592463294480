import {MixedControlState} from '@controls/seazone-checkbox';

import {UserPermissions} from '../../modules/permissions';

export class PermissionsGroupDto {
  constructor(
    public readonly name: string,
    public readonly permissions: PermissionItemDto[],
  ) {
  }
}

export class PermissionItemDto {
  constructor(
    public readonly name: UserPermissions,
    public readonly description: string,
    public readonly relatedToPermission: UserPermissions[],
    public readonly priority: number,
    public readonly availableAfterEnable: UserPermissions[] | null,
    public readonly disablePermissions: DisablePermissionDto | null,
  ) {
  }
}

export class DisablePermissionDto {
  constructor(
    public readonly disable: UserPermissions[],
    public readonly entry: UserPermissions[],
  ) {
  }
}

export interface UserPermissionsForm {
  permissions: UserPermissionsGroupFormDto[];
  all: boolean;
}

export class UserPermissionsGroupFormDto {
  constructor(
    public readonly name: string,
    public readonly permissions: UserPermissionItemFormDto[],
    public readonly all: MixedControlState = 'false',
    public readonly hidden = false,
  ) {
  }
}

export class UserPermissionItemFormDto implements PermissionItemDto {
  readonly name: UserPermissions;
  readonly description: string;
  readonly relatedToPermission: UserPermissions[];
  readonly priority: number;
  readonly availableAfterEnable: UserPermissions[] | null;
  readonly disablePermissions: DisablePermissionDto | null;
  readonly check: boolean = false;
  readonly hidden: boolean = false;

  constructor(
    permission: PermissionItemDto,
    check: boolean,
    hidden = false,
  ) {
    this.name = permission.name;
    this.description = permission.description;
    this.relatedToPermission = permission.relatedToPermission;
    this.priority = permission.priority;
    this.availableAfterEnable = permission.availableAfterEnable;
    this.disablePermissions = permission.disablePermissions;
    this.check = check;
    this.hidden = hidden;
  }
}
