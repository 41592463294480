import {SupplierRoleType} from '../supplier/supplier-services.model';

export class SupplierRoleDto {
  constructor(
    public readonly id: SupplierRoleType,
    public readonly name: string,
    public readonly icon?: string,
  ) {
  }
}

export class SupplierRoleServiceDto {
  constructor(
    public readonly id: number,
    public readonly name: string,
  ) {
  }
}
