export class PositionTypeDto {
  constructor(
    public readonly id: number,
    public readonly name: string,
  ) {
  }
}

export class RolePositionDto {
  constructor(
    public readonly id: number,
    public readonly parentRole: number,
    public readonly name: string,
  ) {
  }
}

export class RolePositionTypeDto {
  constructor(
    public readonly id: number,
    public readonly name: string,
    public readonly positions: RolePositionDto[],
    public readonly skills: RolePositionSkillsDto[],
    public readonly useInFilterOpenOfferByUrl: boolean,
  ) {
  }
}

export class RolePositionSkillsDto {
  constructor(
    public readonly id: number,
    public readonly name: string,
  ) { }
}

export class PositionsGroupDto {
  constructor(
    public readonly id: number,
    public readonly name: string,
  ) { }
}
