import {
  parseUploadFileName,
  parseUploadFileNames,
  parseUploadOriginalName,
  parseUploadOriginalNames,
} from '@helpers/parse-new-upload-file-names';

import {ContractPeriodList} from './add-crew-directories';
import {CrewCalendarStatusType} from './crew-calendar.model';

import {UserPermissions} from '../../modules/permissions';
import {BoatPayload, BoatPreviewDto} from '../boat';
import {DocumentFileDto} from '../boat-documents';
import {CrewCvBankDetailsDto} from '../crew-cv';
import {UserRoleType} from '../directories';
import {CrewStatuses} from '../expense';
import {JobOfferInputFormDto} from '../job-offers';
import {ContractShortFormType, ContractViewI, FinishContractForm} from '../my-contracts';
import {UploadedFile} from '../uploaded-file';

export class CheckCrewPayload {
  constructor(
    public readonly boatId: number,
    public readonly email: string,
    public readonly userGroup: CrewGroupType,
  ) {
  }
}

export type CrewContractMode = 'add' | 'edit';

export enum AddCrewStatus {
  NO_EXIST = 'noExist',
  FREE = 'free',
  EXIST_ON_OTHER_BOAT = 'existOnOtherBoat',
  ON_BOAT = 'existOnYourBoat',
  EXIST_OWNER_MANAGER = 'existInvitationWaitOwnerManager',
  EXIST_CREW_CAPTAIN = 'existInvitationWaitCaptainCrew',
}

export enum CrewSearchStatus {
  LookingJob = 1,
  OnBoard,
  NotActive,
  Hidden,
  Vacation,
}

export class CrewSearchStatusItem {
  constructor(
    public readonly id: CrewSearchStatus,
    public readonly name: string,
    public readonly style: string,
    public readonly showInSearchFilter: boolean = true,
  ) {
  }
}

export enum CrewGroupType {
  Crew = 'crew',
  Additional = 'additional',
}

export enum VacationRelevant {
  NoRelevant,
  Relevant
}

export enum SeasoneSalaryEnable {
  Disabled,
  Enabled
}

export class CrewExistDto {
  constructor(
    public readonly status: AddCrewStatus,
    public readonly role: string | null = null,
  ) {
  }
}

export class AddCrewDto {
  boatId: number;
  readonly roleSystem: UserRoleType;
  readonly contractTypeId: number | null;
  readonly dateStart: string | null;
  readonly dateEnd: string | null;
  readonly amountSalary: number | null;
  readonly currencyId: number | null;
  readonly period: ContractPeriodList;
  readonly positionTypeId: number | null;
  readonly positionManual: string | null;
  readonly paidVacation: number | null;
  readonly paidVacationRelevant: VacationRelevant | null;
  readonly uploadFiles: string[] | null;
  readonly uploadOriginalFiles: string[] | null;
  readonly filesRemove: number[] | null;
  readonly seasonSalaryEnable: SeasoneSalaryEnable | null;
  readonly seasonSalary: number | null;
  readonly seasonSalaryPeriod: ContractPeriodList;
  readonly seasonStartDate: string | null;
  readonly seasonEndDate: string | null;
  readonly name?: string | null;
  readonly permissions?: UserPermissions[];
  readonly email?: string | null;
  readonly roleTypeId?: number | null;
  readonly jobOfferId?: string | null;
  readonly jobOfferInvitationId?: string | null;
  readonly userId?: string | null;

  constructor(
    form: CrewDetailsFormI,
    boatId: number,
    email: string | null,
    jobOfferId: string | null = null,
    jobOfferInvitationId: string | null = null,
    userId: string | null = null,
  ) {
    this.boatId = boatId;
    this.roleSystem = form.roleSystem;
    this.contractTypeId = form.contractTypeId;
    this.dateStart = form.dateStart;
    this.dateEnd = form.dateEnd;
    this.amountSalary = form.amountSalary;
    this.currencyId = form.currencyId;
    this.period = form.period;
    this.positionTypeId = form.positionTypeId === 'manual' ? null : form.positionTypeId;
    this.positionManual = form.positionTypeId !== 'manual' ? null : form.positionManual;
    this.paidVacation = form.paidVacation;
    const isCrewRole = form.roleSystem !== 'role_boat_owner' && form.roleSystem !== 'role_boat_manager';
    this.paidVacationRelevant = isCrewRole ?
      (form.paidVacationRelevant ? VacationRelevant.NoRelevant : VacationRelevant.Relevant) :
      null;
    this.uploadFiles = parseUploadFileNames(form.files);
    this.uploadOriginalFiles = parseUploadOriginalNames(form.files);
    this.filesRemove = form.filesRemove;
    this.seasonSalaryEnable = isCrewRole ?
      (form.seasonSalaryEnable ? SeasoneSalaryEnable.Enabled : SeasoneSalaryEnable.Disabled) :
      null;
    this.seasonSalary = form.seasonSalary;
    this.seasonSalaryPeriod = form.seasonSalaryPeriod;
    this.seasonStartDate = form.seasonStartDate;
    this.seasonEndDate = form.seasonEndDate;
    this.name = form.name;
    this.permissions = form.permissions;
    this.email = email;
    this.roleTypeId = form.roleTypeId;
    this.jobOfferId = jobOfferId;
    this.jobOfferInvitationId = jobOfferInvitationId;
    this.userId = userId;
  }
}

export interface AddCrewResponseDto {
  userId;
  number;
}

export class CrewDetailsFormFromJobOffer implements CrewDetailsFormI {
  readonly roleSystem: UserRoleType;
  readonly positionTypeId: number | 'manual';
  readonly positionManual: string | null;
  readonly contractTypeId: number;
  readonly dateStart: string;
  readonly dateEnd: string;
  readonly amountSalary: number;
  readonly currencyId: number;
  readonly period: ContractPeriodList;
  readonly permissions?: UserPermissions[];
  readonly name?: string;
  readonly roleTypeId: number;
  readonly paidVacation: number | null;
  readonly paidVacationRelevant: VacationRelevant;
  readonly files: UploadedFile[] | null;
  readonly filesRemove: number[] | null;
  readonly seasonSalaryEnable: SeasoneSalaryEnable;
  readonly seasonSalary: number | null;
  readonly seasonSalaryPeriod: ContractPeriodList;
  readonly seasonStartDate: string | null;
  readonly seasonEndDate: string | null;

  constructor(jobOfferForm: JobOfferInputFormDto) {
    this.roleSystem = 'role_crew';
    this.roleTypeId = jobOfferForm.positionGroupId;
    this.positionTypeId = jobOfferForm.positionId;
    this.contractTypeId = jobOfferForm.jobOfferKindContract
      && jobOfferForm.jobOfferKindContract.length
      && jobOfferForm.jobOfferKindContract[0];
    this.dateStart = jobOfferForm.estimatedStartDate ? jobOfferForm.estimatedStartDate : '';
    this.dateEnd = jobOfferForm.endDate ? jobOfferForm.endDate : '';
    this.amountSalary = jobOfferForm.offeredSalary;
    this.currencyId = jobOfferForm.offeredSalaryCurrencyId;
    this.period = jobOfferForm.salaryPeriod;
  }
}

export class CrewDetailsFormFromContract implements CrewDetailsFormI {
  readonly roleSystem: UserRoleType;
  readonly positionTypeId: number | 'manual';
  readonly positionManual: string | null;
  readonly contractTypeId: number | null;
  readonly dateStart: string;
  readonly dateEnd: string;
  readonly amountSalary: number;
  readonly currencyId: number;
  readonly period: ContractPeriodList;
  readonly permissions?: UserPermissions[];
  readonly name?: string;
  readonly roleTypeId: number | null;
  readonly paidVacation: number | null;
  readonly paidVacationRelevant: VacationRelevant;
  readonly files: UploadedFile[] | null;
  readonly filesRemove: number[] | null;
  readonly seasonSalaryEnable: SeasoneSalaryEnable;
  readonly seasonSalary: number | null;
  readonly seasonSalaryPeriod: ContractPeriodList;
  readonly seasonStartDate: string | null;
  readonly seasonEndDate: string | null;

  constructor(contractForm: ContractShortFormType) {
    this.roleSystem = 'role_crew';
    this.roleTypeId = contractForm.roleTypeId || null;
    this.positionTypeId = contractForm.positionTypeId;
    this.contractTypeId = contractForm.contractTypeId;
    this.files = contractForm.files;
    this.amountSalary = contractForm.amountSalary;
    this.paidVacation = contractForm.paidVacation;
    this.paidVacationRelevant = contractForm.paidVacationRelevant;
    this.currencyId = contractForm.currencyId;
    this.period = contractForm.period;
    this.seasonSalaryEnable = contractForm.seasonSalaryEnable;
    this.seasonSalary = contractForm.seasonSalary;
    this.seasonSalaryPeriod = contractForm.seasonSalaryPeriod;
    this.seasonStartDate = null;
    this.seasonEndDate = null;
  }
}

export class InvitedCrewDto {
  constructor(
    public readonly firstName: string,
    public readonly email: string,
    public readonly role: UserRoleType,
    public readonly groupId: number,
    public readonly positionId: number,
  ) {
  }
}

export class FinishContractParams {
  constructor(
    public readonly firstName: string,
    public readonly lastName: string,
    public readonly userRole: string,
    public readonly positionId: number,
    public readonly positionName: string,
    public readonly endContractDate: string,
  ) {
  }
}

export class UserInfoForFinishContract {
  constructor(
    public readonly userRole: UserRoleType,
    public readonly nameReferral: string,
    public readonly surnameReferral: string,
    public readonly referencePositionId: number | null,
    public readonly referenceEmail: string,
    public readonly referencePhone: string,
    public readonly positionName: string,
    public readonly endDate: string,
    public readonly crewName: string,
  ) {
  }
}

export class FinishContractPayload {
  readonly boatId: number;
  readonly userId: number;
  readonly dateFinish: string | null = null;
  readonly reasonTermination: string | null = null;
  readonly referenceUserId: number | null = null;
  readonly referencePhone: string | null = null;
  readonly referenceEmail: string | null = null;
  readonly referenceOriginalLetter: string | null;
  readonly referenceLetter: string | null;

  constructor(boatId: number, userId: number, form: FinishContractForm | null = null) {
    this.boatId = boatId;
    this.userId = userId;
    if (form) {
      this.dateFinish = form.endDate;
      this.reasonTermination = form.reasonTermination;
      this.referenceUserId = form.referenceUserId;
      this.referencePhone = form.referencePhone;
      this.referenceEmail = form.referenceEmail;
      const {referenceLetter} = form;
      this.referenceLetter = parseUploadFileName(referenceLetter);
      this.referenceOriginalLetter = parseUploadOriginalName(referenceLetter);
    }
  }
}

export class CrewInvitationDto {
  constructor(
    public readonly email: string,
    public readonly boatId: number,
  ) {
  }
}

export class CrewShortInfoDto {
  constructor(
    public readonly id: number,
    public readonly avatar: string,
    public readonly firstName: string,
    public readonly lastName: string,
    public readonly email: string,
    public readonly roleName: string,
    public readonly lastUpdated: string,
    public readonly percentAccountCompletion: number,
    public readonly status: CrewStatuses,
  ) {
  }
}

export class CrewPreviewShortInfo {
  constructor(
    public readonly user: CrewShortInfoDto,
    public readonly completionDataProfile: string | number,
  ) {
  }
}

export class CrewContractsList {
  constructor(
    public readonly list: CrewProfileContractDto[],
  ) {
  }
}

export class CrewProfileContractDto {
  constructor(
    public readonly boat: BoatPreviewDto,
    public readonly position: string,
    public readonly salary: SalaryDto,
    public readonly startDate: string,
    public readonly endDate: string,
    public readonly contract: string,
  ) {
  }
}

export class CrewCvContractDto {
  constructor(
    public readonly contractDetails: ContractViewI,
    public readonly bankDetails: CrewCvBankDetailsDto[],
    public readonly form: ContractForm,
  ) {
  }
}

export class ContractForm {
  readonly id: number;
  readonly crewName: string | null;
  readonly roleTypeId: number | null;
  readonly positionTypeId: number | null;
  readonly positionManual: string | null;
  readonly contractTypeId: number | null;
  readonly amountSalary: number;
  readonly currencyId: number;
  readonly period: ContractPeriodList;
  readonly dateStart: string;
  readonly dateEnd: string;
  readonly paidVacation: number | null;
  readonly paidVacationRelevant: VacationRelevant;
  readonly uploadFiles: string[] | null;
  readonly uploadOriginalFiles: string[] | null;
  readonly removeFiles: number[] | null;
  readonly seasonSalaryEnable: SeasoneSalaryEnable;
  readonly seasonSalary: number | null;
  readonly seasonSalaryPeriod: ContractPeriodList;
  readonly seasonStartDate: string | null;
  readonly seasonEndDate: string | null;

  constructor(
    contractId: number,
    form: CrewDetailsFormI,
  ) {
    this.id = contractId;
    this.crewName = form.name || null;
    this.roleTypeId = form.roleTypeId || null;
    this.positionTypeId = form.positionTypeId === 'manual' ? null : form.positionTypeId;
    this.positionManual = form.positionTypeId !== 'manual' ? null : form.positionManual;
    this.contractTypeId = form.contractTypeId;
    this.amountSalary = form.amountSalary;
    this.currencyId = form.currencyId;
    this.period = form.period;
    this.dateStart = form.dateStart;
    this.dateEnd = form.dateEnd;
    this.paidVacation = form.paidVacation;
    this.paidVacationRelevant = form.paidVacationRelevant ? VacationRelevant.NoRelevant : VacationRelevant.Relevant;
    this.uploadFiles = parseUploadFileNames(form.files);
    this.uploadOriginalFiles = parseUploadOriginalNames(form.files);
    this.removeFiles = form.filesRemove;
    this.seasonSalaryEnable = form.seasonSalaryEnable ? SeasoneSalaryEnable.Enabled : SeasoneSalaryEnable.Disabled;
    this.seasonSalary = form.seasonSalary;
    this.seasonSalaryPeriod = form.seasonSalaryPeriod;
    this.seasonStartDate = form.seasonStartDate;
    this.seasonEndDate = form.seasonEndDate;
  }
}

export class CrewContractBankDetailsDto {
  constructor(
    public readonly beneficiaryName: string,
    public readonly beneficiaryAddress: CrewPositionDto,
    public readonly bankName: string,
    public readonly bankAddress: string,
    public readonly accountCurrency: string,
    public readonly accountNumber: string,
    public readonly iban: string,
    public readonly swiftOrBic: string,
    public readonly files: DocumentFileDto[],
  ) {
  }
}

export type CrewListType = 'current' | 'pending' | 'past';

export class CrewListPayload implements BoatPayload {
  constructor(
    public readonly boatId: number,
    public readonly search: string,
    public readonly page: number,
    public readonly limit: number,
  ) {
  }
}

export class CrewPositionDto {
  constructor(
    public readonly name: string,
    public readonly icon: string,
  ) {
  }
}

export class CrewCalendarDto {
  constructor(
    public readonly status: CrewCalendarStatusType,
    public readonly date: string,
  ) {
  }
}

export class ExpectedSalaryDto {
  constructor(
    public readonly value: number,
    public readonly period: number,
    public readonly currency: string,
  ) {
  }
}

export class CrewRatingPayload {
  constructor(
    public boatCrewId: number,
    public rating: number | null,
  ) {
  }
}

export interface CrewDocumentsParams {
  readonly boatId: number;
  readonly userId: number;
}

export class CrewShortItemDto {
  constructor(
    public id: number,
    public name: string,
  ) {
  }
}

export interface CrewInvitationFormI {
  email: string;
  userGroup: CrewGroupType;
}

export interface CrewDetailsFormI {
  roleSystem: UserRoleType;
  positionTypeId: number | 'manual';
  positionManual: string | null;
  contractTypeId: number | null;
  dateStart: string;
  dateEnd: string;
  amountSalary: number;
  currencyId: number;
  period: ContractPeriodList;
  paidVacation: number | null;
  paidVacationRelevant: VacationRelevant;
  files: UploadedFile[] | null;
  filesRemove: number[] | null;
  seasonSalaryEnable: SeasoneSalaryEnable;
  seasonSalary: number | null;
  seasonSalaryPeriod: ContractPeriodList;
  seasonStartDate: string | null;
  seasonEndDate: string | null;
  permissions?: UserPermissions[];
  name?: string;
  jobOfferId?: number;
  jobOfferInvitationId?: number;
  roleTypeId?: number | null;
  id?: number;
}

export type AddCrewFormStep = 'user' | 'permissions';

export enum SalaryPeriod {
  DAILY,
  MONTHLY,
}

export class SalaryDto {
  constructor(
    public readonly amount: number,
    public readonly currency: string,
    public readonly period?: SalaryPeriod,
  ) {
  }
}
