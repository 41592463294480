export type CurrencyDto = Readonly<{
  id: number;
  name: string;
  badge: string;
  general?: boolean;
  status: boolean;
  exchangeRate: number;
}>;

export class CurrencyDirectoriesDto {
  constructor(
    public readonly id: number,
    public readonly name: string,
    public readonly badge: string,
  ) { }
}

export interface ExchangeRateDto {
  exchangeRate: number;
}

export type BoatCurrentCurrency = CurrencyDto | null;

export const activeCurrency = (currencyList: CurrencyDto[]): CurrencyDto[] =>
  currencyList.filter(currency => currency.status);
