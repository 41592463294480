import {ActivityTypeDto} from './activity-type.model';
import {BoatAreaDto} from './boat-area.model';
import {BoatTypeSectionDto} from './boat-type-section.model';
import {BrandDto} from './brand.model';
import {CharterAdditionalPaymentMethodDto} from './charter-additional-payment-method.model';
import {CharterContractStatusDto} from './charter-contract-status.model';
import {CharterKindDto} from './charter-kind.model';
import {ClassNameDto} from './class-name';
import {CompanyTypeDto} from './company-type.model';
import {ContractTypeDto} from './contract-type.model';
import {CountryDto} from './country.model';
import {CurrencyDirectoriesDto} from './currency.model';
import {CvAvailableStatusDto} from './cv-available-status.model';
import {CvDocumentDefaultDto} from './cv-document-default.model';
import {CvDocumentDto} from './cv-document.model';
import {CvVisaGroupDto} from './cv-visa-group.model';
import {GenderDto} from './gender.model';
import {GeneralSettingsDto} from './general-settings.model';
import {GuestKindTypeDto} from './guest-kind-type.model';
import {LanguageLevelDto} from './language-level.model';
import {LanguageDto} from './language.model';
import {MaritalStatusDto} from './martial-status.model';
import {OutstandingPaymentOrderStatusDto} from './outstanding-payment-order-status.model';
import {OutstandingPaymentStatusColorItemDto} from './outstanding-payment-status-color.model';
import {
  ApaPaymentMethodDto,
  CardsPaymentMethodDto,
  PaymentMethodDto,
  PettyCashPaymentMethodDto,
} from './payment-method.model';
import {PermissionsGroupDto} from './permission-group.model';
import {PersonalCharacteristicDto} from './personal-characteristic.model';
import {PositionTypeDto, RolePositionTypeDto} from './position-type.model';
import {RoleDto} from './role.model';
import {SearchRegionItemDto} from './search-region.model';
import {SupplierBrandDto} from './supplier-brand.model';
import {SupplierCatalogRoleDto} from './supplier-catalog.model';
import {SupplierPaymentTermDto} from './supplier-payment-term';
import {SupplierRoleDto} from './supplier-roles.model';
import {SupplierGeneralTag} from './supplier-tag.model';
import {TableSettingsDto} from './table-settings';
import {TimezoneItemDto} from './timezone.model';
import {BoatTypeDto} from './type.model';
import {UniformSizeDto} from './uniform-size.model';
import {WorkPreferencesCurrencyDto} from './work-preferences-currency.model';

import {BoatFleetUserManagePermissionsDto} from '../boats-fleet';
import {UnpublishReasonI} from '../job-offers';

export enum DirectoryStatus {
  DISABLED,
  ENABLED
}

export class DirectoriesDto {
  constructor(
    public readonly role: RoleDto[],
    public readonly country: CountryDto[],
    public readonly gender: GenderDto[],
    public readonly boatType: BoatTypeDto[],
    public readonly boatActivityType: ActivityTypeDto[],
    public readonly brand: BrandDto[],
    public readonly paymentMethod: PaymentMethodDto[],
    public readonly apaPaymentMethod: ApaPaymentMethodDto[],
    public readonly cardStatementPayment: CardsPaymentMethodDto[],
    public readonly contractType: ContractTypeDto[],
    public readonly positionType: PositionTypeDto[],
    public readonly maritalStatus: MaritalStatusDto[],
    public readonly uniformSize: UniformSizeDto[],
    public readonly rolePositionType: RolePositionTypeDto[],
    public readonly className: ClassNameDto[],
    public readonly language: LanguageDto[],
    public readonly languageLevel: LanguageLevelDto[],
    public readonly boatArea: BoatAreaDto[],
    public readonly workPreferencesCurrency: WorkPreferencesCurrencyDto[],
    public readonly personalCharacteristic: PersonalCharacteristicDto[],
    public readonly cvAvailable: CvAvailableStatusDto[],
    public readonly cvDocumentDefault: CvDocumentDefaultDto[],
    public readonly cvDocumentList: CvDocumentDto[],
    public readonly cvVisaGroup: CvVisaGroupDto[],
    public readonly permissionGroups: PermissionsGroupDto[],
    public readonly currency: CurrencyDirectoriesDto[],
    public readonly settingsGeneral: GeneralSettingsDto,
    public readonly companyType: CompanyTypeDto[],
    public readonly reasonOfUnpublishing: UnpublishReasonI[],
    public readonly charterKind: CharterKindDto[],
    public readonly charterContractStatus: CharterContractStatusDto[],
    public readonly guestKindType: GuestKindTypeDto[],
    public readonly charterAdditionalPayment: CharterAdditionalPaymentMethodDto[],
    public readonly searchRegion: SearchRegionItemDto[],
    public readonly outstandingPaymentWorkOrderStatus: OutstandingPaymentOrderStatusDto[],
    public readonly supplierDictionaryRoles: SupplierRoleDto[],
    public readonly supplierCatalogPaymentTerms: SupplierPaymentTermDto[],
    public readonly supplierGeneralTags: SupplierGeneralTag[],
    public readonly supplierDictionaryBrands: SupplierBrandDto[],
    public readonly supplierDictionaryCatalog: SupplierCatalogRoleDto[],
    public readonly boatTypeSection: BoatTypeSectionDto[],
    public readonly fleetPermission: BoatFleetUserManagePermissionsDto[],
    public readonly outstandingPaymentStatusColor: OutstandingPaymentStatusColorItemDto[],
    public readonly boatEquipmentBrand: BrandDto[],
    public readonly boatPettyCashBalancePaymentMethod: PettyCashPaymentMethodDto[],
    public readonly timezoneGroup: TimezoneItemDto[],
    public readonly boatTaskLogSettings: TableSettingsDto[],
  ) {
  }
}

/**
 * Enum for selecting disabled/enabled items. Also needed for displaying disabled item value in dropdowns
 */
export enum DirectoryItemStatus {
  enabled = 1,
  disabled
}

/**
 * Basic list item
 */
export interface ListItemDto {
  readonly id: number;
  readonly name: string;
}

/**
 * List item which have status. Need for showing/hiding element in lists
 */
export interface DynamicListItem extends ListItemDto {
  readonly status: DirectoryItemStatus;
}

export type SubcategoryItem = Omit<CategoryDirectoryItem, 'subcategory'>;

/**
 * List item for category with list of nested subcategories
 */
export interface CategoryDirectoryItem extends DynamicListItem{
  readonly subcategory: SubcategoryItem[];
}

/**
 * Basic object with list data
 */
export interface ListDataDto<T> {
  readonly list: T[];
}
